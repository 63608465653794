@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');


:root {
    --green: #9ccc50;
    --blue: #003768;
}
.App {
  text-align: center;
  font-family: 'DM Sans', sans-serif;
}
h1 {
  color: var(--blue);
  font-family: 'DM Sans', sans-serif;
  font-size: 3em!important;
  cursor: pointer;
  z-index: 1;
}
h2 {
  font-size: 2em;
  font-family: 'DM Sans', sans-serif;
}
.PropName {
  font-weight: bold;
  color: #6264A7;
}
.Logo {
  font-size: 45pt;
  color: #6264A7;
}
.Error {
  color: red;
}

.fas.fa-users {
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 300px;
}
.main_container {
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    border-radius: 25px;
    margin: 20px auto;
    box-shadow: 2px 2px 6px 0 #8f8f8f;
    background-image: url(./background.jpg);
    background-size: cover;
}
.members_container {
    margin: 20px 0;
    z-index: 1
}

.members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.members > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    box-shadow: 0px 0px 4px 2px #8f8f8f;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 400;
    margin: 5px;
    cursor: pointer;
}

.joining {
    background-color: var(--blue);
}
.not_joining {
    background-color: grey;
}

.result {
    color: var(--green);
    font-size: 3em;
}

.options {
    width: 95%;
    text-align: right;
    font-size: 24px;
    color: var(--blue);
    margin: 10px auto;
}

.options > span {
    cursor: pointer;
}

